import { UserRole } from '../generated';

const hasAnyRole = (userRoles: UserRole[], roles: UserRole[]) =>
  userRoles?.some((u) => roles.some((r) => r === u));

export const hasSupport = (roles: UserRole[]) =>
  hasAnyRole(roles, [UserRole.Support]);
export const hasAdmin = (roles: UserRole[]) =>
  hasAnyRole(roles, [UserRole.Admin, UserRole.Support]);
export const hasProvider = (roles: UserRole[]) =>
  hasAnyRole(roles, [UserRole.Provider, UserRole.Support]);
export const hasSupervisor = (roles: UserRole[]) =>
  hasAnyRole(roles, [UserRole.Supervisor, UserRole.Support]);
export const hasHR = (roles: UserRole[]) =>
  hasAnyRole(roles, [UserRole.Hr, UserRole.Support]);
