import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  gql,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { useRouter } from 'next/router';
import { SentryLink } from 'apollo-link-sentry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql/language';
import { useCookies } from 'react-cookie';
import { onError } from '@apollo/client/link/error';
import qs from 'qs';
import { ErrorPageView } from '../views/ErrorPageView';
import { FormattedMessage } from 'react-intl';

export const GraphQLProvider: React.FunctionComponent<
  React.PropsWithChildren<{ unAuthenticated?: boolean }>
> = ({ children, unAuthenticated }) => {
  const [willRedirect, setWillRedirect] = React.useState(false);
  const router = useRouter();
  const [cookies] = useCookies(['guidin-websocket-endpoint']);
  const headers: any = React.useMemo(
    () => ({
      'x-user-locale': router.locale!,
      'Content-Type': 'application/json',
    }),
    [router.locale],
  );

  if (unAuthenticated) {
    headers['x-guidin-unauthenticated'] = 'true';
  }
  const client = React.useMemo(() => {
    const wsLink =
      global['WebSocket'] && !unAuthenticated
        ? new GraphQLWsLink(
            createClient({
              url: cookies['guidin-websocket-endpoint'],
              webSocketImpl: global['WebSocket'],
              shouldRetry: () => true,
              connectionParams: async () => {
                const { data } = await fetch('/api/graphql', {
                  method: 'POST',
                  headers,
                  body: JSON.stringify({
                    query: print(gql`
                      query GetSubscriptionTicket {
                        subscriptionTicket
                      }
                    `),
                  }),
                }).then((r) => r.json());
                return { ticket: data.subscriptionTicket };
              },
            }),
          )
        : null;
    const httpLink = new HttpLink({
      uri: '/api/graphql',
      headers,
    });

    const terminatingLink = wsLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          wsLink,
          httpLink,
        )
      : httpLink;

    return new ApolloClient({
      link: ApolloLink.from([
        onError(({ operation, forward, graphQLErrors }) => {
          const errorCodes =
            graphQLErrors?.map((e) => parseInt(e.message.split(' ')[2])) ?? [];
          if (errorCodes.includes(401)) {
            setWillRedirect(true);
            setTimeout(() => {
              window.location.replace(
                `/${router.locale}/login?${qs.stringify({
                  return_to: `${window.location.pathname}${window.location.search}`,
                })}`,
              );
            }, 2000);
          }
          return forward(operation);
        }),
        new SentryLink({
          attachBreadcrumbs: {
            includeQuery: true,
            includeError: true,
            includeVariables: true,
            includeFetchResult: true,
          },
          setTransaction: true,
          setFingerprint: true,
        }),
        terminatingLink,
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }, [router.locale, cookies, headers, unAuthenticated]);

  if (willRedirect) {
    return (
      <ErrorPageView
        forceHideNav={true}
        title={
          <FormattedMessage
            defaultMessage={'Session has expired'}
            description={'error title when session has been expired'}
          />
        }
        description={
          <FormattedMessage
            defaultMessage={'Please log in again'}
            description={'error description when session has been expired'}
          />
        }
      />
    );
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
